<template>
  <div id="inspectorId">
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm quản lý kiểm định viên'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="downloadExampleFile"
        @clickExportExcel="downloadExportFile"
        @importFile="importFileExcel($event)"
        @clickAdd="showModalCreate"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã kiểm định viên -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ $t(props.row.code) }}</span>
            </b-badge>
          </span>
          <!-- ngày sinh -->
          <span v-else-if="props.column.field == 'birthDay'">
            {{ props.row.birthDay | formatDateToDDMM }}
          </span>
          <!-- ngày cấp -->
          <span v-else-if="props.column.field == 'dateActive'">
            {{ props.row.dateActive | formatDateToDDMM }}
          </span>
          <!-- ngày hết hiệu lực -->
          <span v-else-if="props.column.field == 'dateExpired'">
            {{ props.row.dateExpired | formatDateToDDMM }}
          </span>

          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'action'">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="Edit3Icon"
                size="18"
                class="text-body"
                @click="showModalEdit(props.row.id)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body ml-2"
                @click="deleteItem(props.row.id)"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!--Tạo kiểm định viên-->
      <modal-create-inspector
        :id="modalCreateInspector"
        :dataDetailInspector="dataDetailInspector"
        :type="modalType"
        :title="modalType === 'add' ? 'Thêm mới kiểm định viên':'Chỉnh sửa kiểm định viên'"
        @handleCreateInspector="handleCreateInspector"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'

import { formatDateToDDMMHHMM, formatDateToDDMM } from '@core/utils/filter'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateInspector from './components/ModalCreateInspector.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    ModalCreateInspector,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMMHHMM,
    formatDateToDDMM,
  },
  data() {
    return {
      modalType: null,
      totalRecord: 0,
      dataList: [],
      columns: [
        {
          label: 'MÃ KIỂM ĐỊNH VIÊN',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên kiểm định viên',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY SINH',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Số CMT/CCCD/HC',
          field: 'identityCardNumber',
          sortable: false,
        },
        {
          label: 'SỐ HIỆU CHỨNG CHỈ',
          field: 'certificateNumber',
          sortable: false,
        },
        {
          label: 'NGÀY HIỆU LỰC',
          field: 'dateActive',
          sortable: false,
        },
        {
          label: 'NGÀY HẾT HẠN',
          field: 'dateExpired',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          width: '175px',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalCreateInspector: 'comfirm-create',
      deleteIds: [],
      showBtnMultiDelete: false,
      inspectorId: null,
      dataDetailInspector: {},
      arrayExcel: [
        'code',
        'worker',
        'certificateNumber',
        'dateActive',
        'dateExpired',
      ],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    ...mapActions('inspector', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_INSPECTOR, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalCreateInspector)
    },
    // click show modal sửa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.inspectorId = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_INSPECTOR}${this.inspectorId}`)
      this.dataDetailInspector = data
      this.$bvModal.show(this.modalCreateInspector)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    async handleCreateInspector(val) {
      if (this.modalType === 'edit') {
        val.append('id', this.inspectorId)
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_INSPECTOR, val).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Sửa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalCreateInspector)
          }
          this.fetchData(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }

      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_INSPECTOR, val).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Thêm mới thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalCreateInspector)
          }
          this.fetchData(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_INSPECTOR, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    async getValidData(dataInput) {
      const datafile = dataInput.map(element => ({
        ...element,
        dateActive: Date.parse(element.dateActive) ? `${new Date(element.dateActive).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
        dateExpired: Date.parse(element.dateExpired) ? `${new Date(element.dateExpired).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
      }))
      const model = {
        listExcel: datafile,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-inspector' })
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
