<template>
  <b-modal
    :id="id"
    size="sm"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateInspector"
    @hidden="resetState"
  >
    <div class="modal-accept">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="InputHelp">Mã kiểm định viên <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                id="InputHelp"
                v-model="dataInput.Code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="basicInput">Người lao động <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="customSelectVali"
            >
              <v-select
                v-model="dataInput.WorkerId"
                :reduce="title => title.id"
                label="name"
                :options="dataAllWorker || []"
                :placeholder="'Lựa chọn người lao động'"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.name }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ option.name }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Số hiệu chứng chỉ <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="CertificateNumber"
              rules="required"
              :custom-messages="CertificateNumber"
            >
              <b-form-input
                id="InputHelp"
                v-model="dataInput.CertificateNumber"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Ngày hiệu lực</label>
            <date-time-picker
              v-model="dataInput.DateActive"
              :disabledInput="true"
            />
          </b-form-group>

          <b-form-group>
            <label>Ngày hết hạn</label>
            <date-time-picker
              v-model="dataInput.DateExpired"
              :disabledInput="true"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
      <b-form-group>
        <label>Chọn file</label>
        <div class="d-flex">
          <b-form-input
            v-model="dataInput.FileName"
            :disabled="true"
          >
            {{ nameFile }}
          </b-form-input>
          <input
            id="file"
            ref="file-second"
            type="file"
            class="input-file"
            @change="importFileExcel"
          />
          <b-button
            variant="outline-primary"
            class="btn-button-file ml-1"
            @click="inputFile"
          >
            <div
              class="d-flex"
            >
              <feather-icon
                icon="UploadIcon"
                for="file"
                class="input_label text-primary"
              />
              <label
                class="text-primary ml-50"
              >Tải lên</label>
            </div>
          </b-button>
        </div>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    dataDetailInspector: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      customCode: {
        required: 'Mã kiểm định viên là bắt buộc',
      },
      customSelectVali: {
        required: 'Người lao động là bắt buộc',
      },
      CertificateNumber: {
        required: 'Số hiệu chứng chỉ là bắt buộc',
      },
      nameFile: null,
      dataInput: {
        Code: '',
        WorkerId: '',
        CertificateNumber: '',
        DateActive: '',
        DateExpired: '',
        FileName: '',
      },
      modelFormData: {},
    }
  },
  computed: {
    ...mapGetters('workerPermit', ['dataAllWorker']),
  },
  watch: {
    dataDetailInspector(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          Code: this.dataDetailInspector.code,
          WorkerId: this.dataDetailInspector.workerId,
          CertificateNumber: this.dataDetailInspector.certificateNumber,
          DateActive: this.dataDetailInspector.dateActive,
          DateExpired: this.dataDetailInspector.dateExpired,
          FileName: this.dataDetailInspector.fileName,
        }
      }
    },
  },
  mounted() {
    this.fetchDataComboboxAllWorker()
  },
  methods: {
    ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),

    handleCreateInspector(bvModalEvt) {
      const formData = new FormData()
      formData.append('IsSecure', this.modelFormData.isSecure)
      formData.append('formFile', this.modelFormData.files)
      formData.append('Code', this.dataInput.Code)
      formData.append('WorkerId', this.dataInput.WorkerId)
      formData.append('CertificateNumber', this.dataInput.CertificateNumber)
      formData.append('DateActive', this.dataInput.DateActive)
      formData.append('DateExpired', this.dataInput.DateExpired)
      formData.append('FileName', this.dataInput.FileName)
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          const datestart = new Date(this.dataInput.DateActive).getTime()
          const dateEnd = new Date(this.dataInput.DateExpired).getTime()

          if (datestart > dateEnd) {
            this.$root.$bvToast.toast('Ngày hết hạn không nhỏ hơn ngày hiệu lực', {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          } else {
            this.$emit('handleCreateInspector', formData)
          }
        }
      })
    },
    resetState() {
      this.dataInput = {
        Code: '',
        WorkerId: '',
        CertificateNumber: '',
        DateActive: '',
        DateExpired: '',
        FileName: '',
      }
    },
    inputFile() {
      this.$refs['file-second'].click()
    },
    importFileExcel(e) {
      this.dataInput.FileName = this.$refs['file-second'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .action {
    display: flex;
    justify-content: end;
  }
  .btn-button-file {
    width: 40%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>
