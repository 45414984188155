var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inspectorId"}},[_c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm quản lý kiểm định viên',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete},on:{"clickDelete":_vm.deleteItems,"clickDowloadSample":_vm.downloadExampleFile,"clickExportExcel":_vm.downloadExportFile,"importFile":function($event){return _vm.importFileExcel($event)},"clickAdd":_vm.showModalCreate,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"sort-options":{
        enabled: false,
      },"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.$t(props.row.code)))])])],1):(props.column.field == 'birthDay')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.birthDay))+" ")]):(props.column.field == 'dateActive')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.dateActive))+" ")]):(props.column.field == 'dateExpired')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.dateExpired))+" ")]):(props.column.field == 'action')?_c('span',[_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Edit3Icon","size":"18"},on:{"click":function($event){return _vm.showModalEdit(props.row.id)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}}),_c('modal-create-inspector',{attrs:{"id":_vm.modalCreateInspector,"dataDetailInspector":_vm.dataDetailInspector,"type":_vm.modalType,"title":_vm.modalType === 'add' ? 'Thêm mới kiểm định viên':'Chỉnh sửa kiểm định viên'},on:{"handleCreateInspector":_vm.handleCreateInspector}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }